
import PageTemplate from "./pageTemplate";
import * as React from "react";

export default props => (
    <div style={{
        backgroundImage: `url("${props.image}")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
    }}>
        {props.children}
    </div>
)