import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { AspectImage } from "@theme-ui/components";
import D2 from "../images/d2.jpg";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Nicht auf die Masse, auf die Qualität kommt es an.`}</h2>
    <p>{`Zu einer zeitgenössischen Finanz- und Vermögensplanung gehört heute nicht mehr nur, dass man die Fonds einer Kapitalanlagegesellschaft,
die Altersvorsorgepläne einer Versicherungsgesellschaft oder die Risikoabsicherung einer Sachversicherung überschaut und anbieten kann.
Denn wie überall im Leben gibt es, wo Stärken sind, auch Schwächen, deckt die günstigste Risikoversicherung nicht alle Risiken ab, ist eine Rentenversicherung vielleicht preiswert, aber die finanzielle Situation des Versicherers bereits angespannt und so weiter und so weiter ...
Deshalb ist es sicherlich weder wichtig noch notwendig, dass man auf allen Sektoren alles anbieten kann. Dort wo es um den Preis geht, sollte man jedoch die günstigsten Tarife zur Auswahl haben, wo es um den Umfang der Leistungen geht, dürfen die leistungsstärksten Gesellschaften nicht fehlen. Und wo es um Nachhaltigkeit geht, dort müssen die Gesellschaften mit der größten Finanzkraft an erster Stelle stehen. Faktisch können wir alles oder zumindest fast alles darstellen, was der deutsche Kapital- und Versicherungsmarkt bietet. Schwerpunktmäßig konzentrieren wir uns freilich auf Gesellschaften mit einer hervorragenden Finanzkraft, einem überdurchschnittlichen Preis-Leistungs-Verhältnis und im Investmentbereich auf Gesellschaften mit einer langen Tradition und Fonds, die nicht gerade erst einen Konjunkturzyklus alt sind. Kurz und gut: Wir stellen die Qualität in den Vordergrund unserer Beratung.`}</p>
    <AspectImage src={D2} ratio={16 / 7} mdxType="AspectImage" />
    <h2>{`Die Zukunft mit Augenmaß planen`}</h2>
    <p>{`Das Sparbuch aus Großmutters Zeiten ist längst nicht mehr up to date. Moderne Formen der Vermögensbildung sind an seine Stelle getreten. Wer mit der Zeit geht, investiert sein Geld heute in Fonds. Wer gleichzeitig Geld und Steuern sparen will, sollte eine Rürup- oder eine Riesterrente wählen. Und wer im Alter keine Miete mehr zahlen will, für den ist Immobilienbesitz die beste Lösung. Doch schon bei den Steuern fängt die Sache an, schwierig zu werden. Für den einen Fonds fällt Kapitalertragssteuer an, für den anderen (noch) nicht. Für den einen Selbständigen mag sich der Abschluss einer Rürup-Rentenversicherung steuerlich lohnen, für den anderen nicht. Schließlich die beinahe unüberschaubare Zahl der Anbieter und Lösungen. Etwa 130 Versicherer konkurrieren am deutschen Markt. Die einen setzen auf eine hohe Präsenz in der Fläche. Andere stützen sich auf Strukturvertriebe. Und wieder andere bauen auf Medienwerbung. Doch nicht jeder dieser Anbieter hält am Ende auch, was er verspricht. Finanziell angeschlagene Gesellschaften bemühen sich nicht weniger um Neuabschlüsse als Versicherer, denen es finanziell gut geht. Gleichzeitig werben etwa 8000 Einzelfonds mit teilweise zweistelligen Renditen. Wie man weiss, geht nicht jede dieser Versprechungen in Erfüllung. Wo man viel gewinnen kann, kann man auch viel verlieren. Wie soll man sich deshalb am Ende verhalten? Wonach soll man sich richten? Und was tun angesichts einer immer weniger planbaren und sicheren finanziellen und beruflichen Zukunft? Ich kann hier freilich nur Hilfestellungen geben. Die Zukunft kenne natürlich auch ich nicht. Was ich mir auf die Fahnen geschrieben habe, ist Fairness und Transparenz in Bezug auf die Kosten, so dass am Ende beide Seiten von einer Zusammenarbeit profitieren.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      