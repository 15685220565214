import * as React from "react"
import PageTemplate from "../componets/pageTemplate";
import {AspectImage, Card, Container, Text, Flex, Box, Divider} from "@theme-ui/components";
import {Heading, Image, Link} from "theme-ui";
import BgDiv from "../componets/bgContainer"
import Dreden from "../images/dresden.jpg"
import Logo from "../images/logo.png"
import IndexMD from "../mds/index.mdx"

const TextEclipses={
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
}

// markup
const IndexPage = () => {
  return (
      <PageTemplate>
          <title>DBS Dresden</title>
            <BgDiv image={Dreden}>
                    <Container sx={{
                        textAlign: "center",
                        maxWidth: "500px",
                        bg:'muted',
                        p:3,

                    }}>
                        <Container >
                            <Image sx={{
                                    maxWidth: 200
                                }} src={Logo}>
                            </Image>
                        </Container>
                        <Container
                            sx={{
                                p:2,
                                textAlign: "left",
                            }}
                        >
                            <Heading>Dr. Thomas Blume</Heading>
                            <Heading as="h3">unabhängiger Finanz- und Versicherungsmakler </Heading>
                            <Divider />

                                <Heading style={TextEclipses} as='h4'> <b>Adresse:</b> <Link href="/kontakt#adresse">Bautzner Str. 75, 01099 Dresden</Link></Heading>
                                <Heading style={TextEclipses} as='h4'> <b>Telefon:</b> <Link href="/kontakt#kontakt">0351 8108704 / 0179 2791915</Link></Heading>


                        </Container>
                    </Container>
            </BgDiv>
            <IndexMD></IndexMD>
      </PageTemplate>

  )
}

export default IndexPage
